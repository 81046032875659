import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Newsletter from '../../components/Newsletter'
import GitHub from '../../images/github.svg'
import ImageGatsbyPostManagerLogo from '../../images/projects/gatsby-post-manager-logo.png'
import ProjectImage from '../../atoms/ProjectImage'
import ProjectLinks from '../../atoms/ProjectLinks'

export default function ProjectGatsbyPostManager({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Projects | Gatsby Post Manager"
        keywords={[`projects`, `python`, `javascript`, `react`, 'open source']}
      />
      <section>
        <article>
          <h1>Gatsby Post Manager</h1>
          <a href="https://github.com/steven-mercatante/gatsby-post-manager">
            <ProjectImage
              src={ImageGatsbyPostManagerLogo}
              alt="Gatsby Post Manager logo"
            />
          </a>
          <h2>Goal</h2>
          <p>
            Gatsby Post Manager (<code>gpm</code>) is a command line tool that
            helps authors keep track of markdown based content and their status
            (published, pending, unpublished), and provides a way to bootstrap
            new articles.
          </p>
          <p>
            I tend to write multiple articles in batches, but I don't
            necessarily want to publish them immediately. Traditional CMSs let
            you schedule when articles go live, but I don't have that capability
            since my Gatsby site uses flat files for content. Gatsby Post
            Manager lets me easily schedule when I should publish content.
          </p>
          <p>
            Another pain point this tool solves is its ability to create new
            articles from a template. Used in combination with a custom{' '}
            <Link to="/automating-gatsby-post-creation">
              automation workflow
            </Link>
            , this tool removes the repetitive and tedious steps I used to take
            whenever I started a new article.
          </p>
          <p>
            Even though the project is called Gatsby Post Manager, it can be
            used in any workflow in which markdown content is authored.
          </p>
          <h2>Technologies</h2>
          <p>
            Gatsby Post Manager was written in Node.js. It uses the great{' '}
            <a href="https://yargs.js.org/">yargs</a> package to define the
            actual CLI interface, and is installable as a global npm package.
          </p>
        </article>
      </section>
      <ProjectLinks>
        <a href="https://github.com/steven-mercatante/gatsby-post-manager">
          <GitHub height="26" />
          &nbsp; View source code
        </a>
      </ProjectLinks>
      <Newsletter
        body="Subscribe to be notified when I publish new content and release new projects."
        referrer="projects - gpm"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectGatsbyPostManager {
    site {
      siteMetadata {
        title
      }
    }
  }
`
